import { createContext, ReactNode, useEffect, useState } from "react";
import { IOptionProps } from "../@types/reactSelect/IOption";
import { Card } from "../components/Card/Index";
import axios from "../services/axios";

export type IOptionsEditOnboarding =
  | "farms"
  | "areas"
  | "micro-areas"
  | "batches"
  | "animal_category"
  | "goals_birth"
  | "goals_death"
  | "create_products"
  | "evaluations"
  | "task_evaluations"
  | "create_evaluator";

type ICard = {
  id: number;
  initial: string;
  initialBatch?: string;
  name: string;
  qtdArea?: number;
  qtdMicroArea?: number;
  qtdAnimals?: number;
};

type EditOnboarding = {
  listCards: ICard[];
  defaultValue: any;
  filterSelected: IOptionsEditOnboarding;
  setFilterSelected: (value: IOptionsEditOnboarding) => void;
  optionForm: IOptionsEditOnboarding | undefined;
  setOptionForm: (value: IOptionsEditOnboarding | undefined) => void;
  setDefaultValue: (value: any) => void;
  idSelected: number;
  setIdSelected: (value: number) => void;
  totalPages: number;
  currentPage: number;
  setCurrentPage: (value: number) => void;
  queryToSearch: string;
  setQueryToSearch: (query: string | undefined) => void;
};

type EditOnboardingProps = {
  children: ReactNode;
};

const EditOnboardingContext = createContext({} as EditOnboarding);

function EditOnboardingProvider({ children }: EditOnboardingProps) {
  const [filterSelected, setFilterSelected] =
    useState<IOptionsEditOnboarding | undefined>(undefined);
  const [listCards, setListCads] = useState<ICard[]>([]);
  const [optionForm, setOptionForm] = useState<
    IOptionsEditOnboarding | undefined
  >(undefined);
  const [idSelected, setIdSelected] = useState<number>(0);
  const [defaultValue, setDefaultValue] = useState<any>({});
  const [totalPages, setTotalPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [queryToSearch, setQueryToSearch] = useState<string | undefined>();

  useEffect(() => {
    if (!optionForm) {
      setIdSelected(0);
      setDefaultValue(undefined);
    }
  }, [optionForm]);

  useEffect(() => {
    setQueryToSearch(undefined);
    setCurrentPage(1);
  }, [filterSelected]);

  useEffect(() => {
    if(filterSelected) {
      switch (filterSelected) {
        case "animal_category":
          break;
        case 'goals_birth':
          break;
        case 'goals_death':
          break;
        case 'create_products':
          break;
        case 'create_evaluator':
          break;
        case 'task_evaluations':
          break;
        default:
          getCards();
          break;
      }
    }
  }, [queryToSearch, filterSelected, currentPage, optionForm]);

  useEffect(() => {
    setCurrentPage(1);
  }, [queryToSearch]);

  function getCards() {
    if (filterSelected && (filterSelected.includes("evaluation") || filterSelected.includes("task_evaluations")))
      return;

    const toSearch = queryToSearch ? `&query=${queryToSearch}` : "";
    axios
      .get(`/${filterSelected}/search?page=${currentPage - 1}${toSearch}`)
      .then((response) => {
        const listCards: ICard[] = response.data.content.map((card: any) => {
          var fullInitials = "";
          var batchInitials = undefined;
          switch (filterSelected) {
            case "farms":
              fullInitials = card.initials;
              break;
            case "areas":
              fullInitials = `${card.farm_initials}-${card.initials}`;
              break;
            case "micro-areas":
              fullInitials = `${card.farm_initials}-${card.area_initials}-${card.initials}`;
              break;
            case "batches":
              fullInitials = `${card.farm_initials}-${card.area_string}`;
              batchInitials = `${card?.initials}`;
              break;
          }
          return {
            id: card.id,
            initial: fullInitials,
            initialBatch: batchInitials,
            name: card.name,
            qtdArea: card?.qtd_areas,
            qtdMicroArea: card?.qtd_micro_areas,
            qtdAnimals: card?.qtd_animals,
          } as ICard;
        });
        setListCads(listCards);
        setTotalPages(Number(response.data.total_pages));
      });
  }

  useEffect(() => {
    if (idSelected != 0) {
      if (filterSelected == "farms") {
        // Requisição da fazenda por idSelected
        axios.get(`/farms/${idSelected}`).then(({ data }) => {
          var defaultForm = {
            id: data?.id,
            name: data?.name,
            initials: data?.initials,
            document: data?.document || "",
            state_registration: data?.state_registration || "",
            owner_user_id: {
              label: data?.owner_user?.name,
              value: data?.owner_user?.id,
            },
            address: {
              state: {
                label: data?.address?.state,
                value: data?.address?.state,
              },
              city: {
                label: data?.address?.city?.name,
                value: data?.address?.city?.code,
              },
            },
            season: data?.properties?.season,
            map_coords: data?.map_coords,
          };
          setDefaultValue(defaultForm);
        });
      } else if (filterSelected == "micro-areas") {
        // Requisição de pasto por idSelected
        axios.get(`/micro-areas/${idSelected}`).then(({ data }) => {
          var defaultForm = {
            id: data?.micro_area_id,
            initials: data?.micro_area_initials,
            farm_id: data?.farm_id,
            farm_initials: data?.farm_initials,
            area_id: data?.area_id,
            area_initials: data?.area_initials,
            map_coords: data?.micro_area_map_coords?.map((coords: any) => ({
              lat: coords?.latitude,
              lng: coords?.longitude,
            })),
            reason_id: data?.micro_area_reason_id,
            batch_initials: data?.batch_initials,
            remaining_area: data?.remaining_area + data?.acreage,
            acreage: data?.acreage?.toFixed(2),
            batchId: data?.batch_id,
            animalsAmount: data?.animals_amount,
          };

          setDefaultValue(defaultForm);
        });
      } else if (filterSelected == "batches") {
        // Requisição de lote por idSelected
        axios.get(`/batches/${idSelected}`).then(({ data }) => {
          var defaultForm = {
            id: data?.id,
            farm_id: data?.farm_id,
            area_id: data?.area_id,
            initialsArea: `${data?.farm_initials}-${data?.area_initials}`,
            initials: data?.initials,
            current_micro_area_id: {
              value: `${data?.current_micro_area_id}`,
            },
            micro_area_route_ids: data?.micro_areas_in_route.map(
              (option: any) => ({
                label: `${data?.farm_initials}-${data?.area_initials}-${option?.initials}`,
                value: `${option?.id}`,
              })
            ),
            category: data?.batch_categories.map((option: any) => ({
              value: `${option?.id}`,
              info: option?.qtd,
            })),
          };

          setDefaultValue(defaultForm);
        });
      }
    }
  }, [idSelected]);

  useEffect(() => {
    if (idSelected != 0 && !optionForm) setOptionForm(filterSelected);
  }, [defaultValue]);

  return (
    <EditOnboardingContext.Provider
      value={
        {
          filterSelected,
          setFilterSelected,
          listCards,
          optionForm,
          setOptionForm,
          idSelected,
          setIdSelected,
          setDefaultValue,
          defaultValue,
          totalPages,
          currentPage,
          setCurrentPage,
          queryToSearch,
          setQueryToSearch,
        } as EditOnboarding
      }
    >
      {children}
    </EditOnboardingContext.Provider>
  );
}

export { EditOnboardingContext, EditOnboardingProvider };
