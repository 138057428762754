import "./styles.css"
import React, { useEffect, useState } from "react";
import { ReactComponent as PlusIcon } from "../../../assets/svg/icons/plus.svg";
import { useEditOnboarding } from "../../../hooks/useEditOnboarding";
import { useTaskCreation } from "../../../hooks/useTaskCreation";
import InfoEvaluation from "../../Tasks/CreateEvaluator/InfoEvaluation";
import axios from "../../../services/axios"
import { IOptionsEvaluation } from "../../ViewTables/Index";
import TableVisualizationRowTask from "./TableVisualizationRow/Index";

interface TableProductsProps {
  listTable: ITaskEvaluation[];
  filter: IOptionsEvaluation | undefined;
}

export default function TableTaskEvaluation({ listTable, filter }: TableProductsProps) {
  const {
    setOptionForm,
  } = useEditOnboarding();
  const { setModalCreateEvaluator, setModalStartEvaluator, modalStartEvaluator } = useTaskCreation();
  const [selectedId, setSelectedId] = useState(0);

  const statusMap = new Map<string, string>()
  statusMap.set("COMPLETED", "Avaliado")
  statusMap.set("PENDING", "Pendente")
  statusMap.set("ERROR", "Erro")

  return (
    <>
      <>
        {listTable && listTable.length > 0 ?
          <>
            <section className="tableHeaderColumnTaskEvaluation">
              <div>Usuário</div>
              <div>Tarefa</div>
              <div>Pasto/Lote</div>
              <div>Status</div>
              <div>Data Criação</div>
              <div>Data Execução</div>
              <div />
            </section>

            <section className="tableViewRows">
              {listTable.map(evaluation =>
                <TableVisualizationRowTask evaluation={evaluation} setSelectedId={setSelectedId} />
              )}
            </section>
          </>
          :
          <div className="emptyTable_container">
            <span> Nenhum resultado encontrado.</span>
          </div>
        }
      </>

      <InfoEvaluation
        selected={selectedId}
        setSelectedId={setSelectedId}
      />
    </>
  )
}