import "./styles.css"
import { CSSProperties, useEffect, useState } from "react";
import { ReactComponent as PastureEvaluationIcon } from "../../../../assets/svg/icons/pastureEvaluation.svg";
import { CounterInputEdit } from "../../../EditOnboarding/components/Input/CounterInput/Index";
import { StaticMapRender } from "../../../EditOnboarding/components/StaticMapRender/Index";
import { RoundedCloseButton } from "../../../Buttons/RoundedCloseButton/Index";
import { ButtonHeightPasture } from "../../../Buttons/ButtonHeightPasture";
import { decimalToDMS } from "../../../../utils/latlng/castDecimalToDMS";
import { MessageBalloon } from "../../../MessageBalloon/Index";
import { ButtonYesOrNo } from "../../../Buttons/ButtonYesOrNo";
import { ButtonPressed } from "../../../Buttons/ButtonPressed";
import { Modal } from "../../../Modal/Default/Index";
import ImageComponent from "../../../ImageComponent";
import axios from "../../../../services/axios";
import { format } from "date-fns";
import { useEditOnboarding } from "../../../../hooks/useEditOnboarding";

interface InfoEvaluationProps {
  selected: number;
  setSelectedId: (e: number) => void;
}

export default function InfoEvaluation({
  selected,
  setSelectedId
}: InfoEvaluationProps) {
  const {
    idSelected
  } = useEditOnboarding();

  const [taskEvaluation, setTaskEvaluation] = useState<IPastureEvaluation>();
  const [microArea, setMicroArea] = useState<IGetMicroArea>();
  const [area, setArea] = useState<IArea>();

  const createEvaluatorModalStyle = {
    padding: "50px",
    width: "910px",
    height: "fit-content",
    borderRadius: 20,
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  } as CSSProperties;

  useEffect(() => {
    if (selected != 0) {
      axios.get(`tasks/pasture-evaluation/${selected}`)
        .then(({ data }) => {
          setTaskEvaluation(data)
        })
        .catch((error) => {
          console.log(error)
        })

      axios.get(`micro-areas/${idSelected}`)
        .then(({ data }) => {
          setMicroArea({
            ...data,
            micro_area_map_coords: data.micro_area_map_coords.map((coord: any) => {
              return {
                lat: coord.latitude,
                lng: coord.longitude
              }
            })
          })
        })
        .catch((error) => {
          console.log(error)
        })
    }
    const contentInfoEvaluation = document.getElementById('contentInfoEvaluation');

    if (contentInfoEvaluation) {
      contentInfoEvaluation.scrollTop = 0
    }
  }, [selected]);

  useEffect(() => {
    if (microArea) {
      axios.get(`areas/${microArea.area_id}`)
        .then(({ data }) => {
          setArea(data)
        })
        .catch((error) => {
          console.log(error)
        })
    }
  }, [microArea]);

  function getImageDescription(obj: PhotoDetails) {
    const lat = decimalToDMS(obj.lat, obj.lng).latitude
    const lng = decimalToDMS(obj.lat, obj.lng).longitude
    return `${lat} ${lng} ${format(new Date(obj.timestamp), `dd/MM/yyyy - hh:mm`)}`
  }

  const mapText = new Map();
  mapText.set("FOLHA_LARGA", "Folha larga")
  mapText.set("FOLHA_ESTREITA", "Folha estreita")
  mapText.set("ANUAL_MOLE", "Anual mole")
  mapText.set("PERENE_DURA", "Perene dura")

  useEffect(() => {
    if (selected == 0)
      setTaskEvaluation(undefined)
  }, [selected]);

  return (
    <Modal
      visible={selected != 0}
      contentStyle={createEvaluatorModalStyle}
    >
      <section>
        <header className="headerInfoEvaluation">
          <div>
            <PastureEvaluationIcon />
            <h1 className="titleInfoEvaluation">Avaliação de pastagem</h1>
          </div>
          <div>
            <RoundedCloseButton
              onClick={() => setSelectedId(0)}
            />
          </div>
        </header>

        <div className="dividerInfoEvoluation" />

        <main className="bodyInfoEvaluation">
          <div className="historyInfoEvaluation" id="contentInfoEvaluation">

            {/*1. Forragem*/}
            <h1>1. Forragem</h1>
            <div className="containerQuestion">
              <MessageBalloon text={"Possui forrageira?"} pointed />
              <ButtonYesOrNo onlyResult optionSelected={taskEvaluation?.pasture_evaluation_fodder_content ? "yes" : "no"} />
            </div>

            {taskEvaluation?.pasture_evaluation_fodder_content ? (
              taskEvaluation?.pasture_evaluation_fodder_content?.fodder_content.map((el, key) => (
                <>
                  {
                    taskEvaluation?.pasture_evaluation_fodder_content &&
                    taskEvaluation?.pasture_evaluation_fodder_content.fodder_content.length > 1 &&
                    <h3>{key + 1}ª Forragem</h3>
                  }
                  <div>
                    <h5>Gênero</h5>
                    <MessageBalloon text={el.fodder_details?.fodder_genus} />
                  </div>

                  <div>
                    <h5>Espécie</h5>
                    <MessageBalloon text={el.fodder_details?.fodder_species} />
                  </div>

                  <div className="containerQuestion">
                    <h3>Foto</h3>
                    <ImageComponent url={el.photo_details.url} />
                    <MessageBalloon text={`${getImageDescription(el.photo_details)}`} />
                  </div>

                  <div className="containerQuestion">
                    <MessageBalloon text={"Qual a proporção da área coberta com planta forrageira?"} pointed />
                    <CounterInputEdit onlyResponse isDisabled defaultValue={el?.proportion} control={undefined} label={"%"} isInline />
                  </div>

                  <div className="containerQuestion">
                    <MessageBalloon text={"Qual a altura do capim?"} pointed />
                    <ButtonHeightPasture onlyResult optionSelected={el?.height} />
                  </div>
                </>
              ))
            ) : (
              <>
              </>
            )}

            {/*2. Invasoras*/}
            <h1>2. Invasoras</h1>
            <div className="containerQuestion">
              <MessageBalloon text={"Possui invasoras?"} pointed />
              <ButtonYesOrNo onlyResult optionSelected={taskEvaluation?.pasture_evaluation_invasive_content ? "yes" : "no"} />
            </div>

            {taskEvaluation?.pasture_evaluation_invasive_content ? (
              taskEvaluation?.pasture_evaluation_invasive_content?.invasive_content.map((el, key) => (
                <>
                  {
                    taskEvaluation?.pasture_evaluation_invasive_content &&
                    taskEvaluation?.pasture_evaluation_invasive_content.invasive_content.length > 1 &&
                    <h3>{key + 1}ª Invasora</h3>
                  }

                  <div className="containerQuestion">
                    <MessageBalloon text={"Qual o tipo de folha?"} pointed />
                    <ButtonPressed onlyResult optionSelected={1} value={1} text={mapText.get(el.leaf_type)} />
                  </div>

                  {el.leaf_type == "FOLHA_LARGA" &&
                    <>
                      <div className="containerQuestion">
                        <MessageBalloon text={"Qual o tipo de invasora?"} pointed />
                        <ButtonPressed onlyResult optionSelected={1} value={1} text={mapText.get(el.invasive_type)} />
                      </div>

                      <div className="containerQuestion">
                        <MessageBalloon text={"Qual a cobertura de invasoras?"} pointed />
                        <CounterInputEdit onlyResponse isDisabled defaultValue={el.coverage} control={undefined} label={"%"} isInline />
                      </div>
                    </>
                  }

                  {el.leaf_type == "FOLHA_ESTREITA" &&
                    <>
                      <div className="containerQuestion">
                        <MessageBalloon text={"Nota"} pointed />
                        <CounterInputEdit onlyResponse isDisabled defaultValue={el.rating} control={undefined} isInline />
                      </div>
                    </>
                  }

                  <div className="containerQuestion">
                    <h3>Foto</h3>
                    <ImageComponent url={el.photo_details.url} />
                    <MessageBalloon text={getImageDescription(el.photo_details)} />
                  </div>
                </>
              ))
            ) : (
              <>
              </>
            )}

            {/*3. Pragas ou doenças*/}
            <h1>3. Pragas ou doenças</h1>
            <div className="containerQuestion">
              <MessageBalloon text={"Possui pragas ou doenças?"} pointed />
              <ButtonYesOrNo onlyResult optionSelected={taskEvaluation?.pasture_evaluation_plague_content ? "yes" : "no"} />
            </div>

            {taskEvaluation?.pasture_evaluation_plague_content ? (
              <>
                <div>
                  <h5>Pragas</h5>
                  <MessageBalloon text={taskEvaluation?.pasture_evaluation_plague_content.plague_content.map(el => el.plague_name).join(", ")} />
                </div>

                {taskEvaluation?.pasture_evaluation_plague_content.plague_content.map((plague, index) => {
                  return (
                    <div key={index} className="containerQuestion">
                      <h3>Foto {index + 1}</h3>
                      <ImageComponent url={plague.photo_details.url} />
                      <MessageBalloon text={getImageDescription(plague.photo_details)} />
                    </div>
                  )
                })}
              </>
            ) : (
              <>
              </>
            )}

            {/*4. Aguada*/}
            <h1>4. Aguada</h1>
            <div className="containerQuestion">
              <MessageBalloon text={"Possui aguada?"} pointed />
              <ButtonYesOrNo onlyResult optionSelected={taskEvaluation?.pasture_evaluation_waterhole_content ? "yes" : "no"} />
            </div>

            {taskEvaluation?.pasture_evaluation_waterhole_content ? (
              <>
                <div>
                  <h5>Estrutura</h5>
                  <MessageBalloon text={taskEvaluation?.pasture_evaluation_waterhole_content?.structure_type} />
                </div>

                <div className="containerQuestion">
                  <MessageBalloon text={"Como é o nível de acesso?"} pointed />
                  <ButtonPressed onlyResult optionSelected={1} value={1} text={taskEvaluation?.pasture_evaluation_waterhole_content.access_level} />
                </div>

                <div className="containerQuestion">
                  <MessageBalloon text={"Precisa de reparo?"} pointed />
                  <ButtonYesOrNo onlyResult optionSelected={taskEvaluation?.pasture_evaluation_waterhole_content.needs_repair ? "yes" : "no"} />
                </div>

                {taskEvaluation?.pasture_evaluation_waterhole_content?.structure_type == "Bebedouro artificial" && (
                  <div>
                  <h5>Tipo de reparo</h5>
                  <MessageBalloon text={taskEvaluation?.pasture_evaluation_waterhole_content?.repair_type} />
                </div>
                )}                

                <div className="containerQuestion">
                  <h3>Foto</h3>
                  <ImageComponent url={taskEvaluation?.pasture_evaluation_waterhole_content.photo_details.url} />
                  <MessageBalloon text={getImageDescription(taskEvaluation?.pasture_evaluation_waterhole_content.photo_details)} />
                </div>
              </>
            ) : (
              <>
              </>
            )}

            {/*5. Suplemento*/}
            <h1>5. Suplemento</h1>
            <div className="containerQuestion">
              <MessageBalloon text={"Possui depósito de suplemento?"} pointed />
              <ButtonYesOrNo onlyResult optionSelected={taskEvaluation?.pasture_evaluation_suplement_content ? "yes" : "no"} />
            </div>

            {taskEvaluation?.pasture_evaluation_suplement_content ? (
              <>
                <div className="containerQuestion">
                  <MessageBalloon text={"Quantos quilos cabem?"} pointed />
                  <CounterInputEdit onlyResponse isDisabled defaultValue={taskEvaluation.pasture_evaluation_suplement_content.weight} control={undefined} label={"Kg"} isInline />
                </div>

                <div className="containerQuestion">
                  <h3>Foto</h3>
                  <ImageComponent url={taskEvaluation?.pasture_evaluation_suplement_content.photo_details.url} />
                  <MessageBalloon text={getImageDescription(taskEvaluation?.pasture_evaluation_suplement_content.photo_details)} />
                </div>
              </>
            ) : (
              <>
              </>
            )}

            {/*6. Cocho*/}
            <h1>6. Cocho</h1>
            <div className="containerQuestion">
              <MessageBalloon text={"Possui cocho?"} pointed />
              <ButtonYesOrNo onlyResult optionSelected={taskEvaluation?.pasture_evaluation_trough_content ? "yes" : "no"} />
            </div>

            {taskEvaluation?.pasture_evaluation_trough_content ? (
              <>
                <div className="containerQuestion">
                  <MessageBalloon text={"O cocho é coberto?"} pointed />
                  <ButtonYesOrNo onlyResult optionSelected={taskEvaluation?.pasture_evaluation_trough_content.has_coverage ? "yes" : "no"} />
                </div>


                <div className="containerQuestion">
                  <MessageBalloon text={"Qual o tamanho em comprimento?"} pointed />
                  <CounterInputEdit onlyResponse isDisabled defaultValue={taskEvaluation?.pasture_evaluation_trough_content.length} control={undefined} label={"m²"} isInline />
                </div>

                <div className="containerQuestion">
                  <MessageBalloon text={"Qual o tamanho em largura?"} pointed />
                  <CounterInputEdit onlyResponse isDisabled defaultValue={taskEvaluation?.pasture_evaluation_trough_content.width} control={undefined} label={"m²"} isInline />
                </div>

                <div className="containerQuestion">
                  <MessageBalloon text={"Quantos lados possui o acesso desse cocho?"} pointed />
                  <ButtonPressed onlyResult optionSelected={1} value={1} text={`${taskEvaluation?.pasture_evaluation_trough_content.sides} lado`} />
                </div>

                <div className="containerQuestion">
                  <MessageBalloon text={"Qual a capacidade do cocho?"} pointed />
                  <CounterInputEdit onlyResponse isDisabled defaultValue={taskEvaluation.pasture_evaluation_trough_content.capacity} control={undefined} label={"Kg"} isInline />
                </div>

                <div className="containerQuestion">
                  <MessageBalloon text={"Precisa de reparo?"} pointed />
                  <ButtonYesOrNo onlyResult optionSelected={taskEvaluation?.pasture_evaluation_trough_content.need_repair ? "yes" : "no"} />
                </div>

                <div>
                  <h5>Tipo de reparo</h5>
                  <MessageBalloon text={taskEvaluation?.pasture_evaluation_trough_content?.repair_type} />
                </div>

                <div className="containerQuestion">
                  <h3>Foto</h3>
                  <ImageComponent url={taskEvaluation?.pasture_evaluation_trough_content.photo_details.url} />
                  <MessageBalloon text={getImageDescription(taskEvaluation?.pasture_evaluation_trough_content.photo_details)} />
                </div>
              </>
            ) : (
              <>
              </>
            )}

            {/*7. Cercas*/}
            <h1>7. Cercas</h1>
            {taskEvaluation?.pasture_evaluation_fence_content ? (
              <>
                <div>
                  <h5>Tipo</h5>
                  <MessageBalloon text={taskEvaluation?.pasture_evaluation_fence_content.fence_type} />
                </div>

                <div>
                  <h5>Material</h5>
                  <MessageBalloon text={taskEvaluation?.pasture_evaluation_fence_content.fence_structure} />
                </div>

                <div className="containerQuestion">
                  <MessageBalloon text={"Qual a quantidade de fios?"} pointed />
                  <CounterInputEdit onlyResponse isDisabled defaultValue={taskEvaluation?.pasture_evaluation_fence_content.wire_quantity} control={undefined} label={" fios"} isInline />
                </div>

                <div className="containerQuestion">
                  <MessageBalloon text={"Precisa de reparo?"} pointed />
                  <ButtonYesOrNo onlyResult optionSelected={taskEvaluation?.pasture_evaluation_fence_content.need_repair ? "yes" : "no"} />
                </div>

                <div className="containerQuestion">
                  <h3>Foto</h3>
                  <ImageComponent url={taskEvaluation?.pasture_evaluation_fence_content.photo_details.url} />
                  <MessageBalloon text={getImageDescription(taskEvaluation?.pasture_evaluation_fence_content.photo_details)} />
                </div>
              </>
            ) : (
              <>
              </>
            )}

            {/*8. Relevo e solo*/}
            <h1>8. Relevo e solo</h1>
            {taskEvaluation?.pasture_evaluation_terrain_content ? (
              <>
                <div>
                  <h5>Relevo</h5>
                  <MessageBalloon text={taskEvaluation?.pasture_evaluation_terrain_content.terrain_type} />
                </div>

                <div>
                  <h5>Solo</h5>
                  <MessageBalloon text={taskEvaluation?.pasture_evaluation_terrain_content.soil_moisture} />
                </div>

                <div className="containerQuestion">
                  <h3>Foto</h3>
                  <ImageComponent url={taskEvaluation?.pasture_evaluation_terrain_content.photo_details.url} />
                  <MessageBalloon text={getImageDescription(taskEvaluation?.pasture_evaluation_terrain_content.photo_details)} />
                </div>
              </>
            ) : (
              <>
              </>
            )}
          </div>



          <div className="contentInfoEvaluation">
            <div className="statusInfoEvaluation">
              <span>Avaliado</span>
              <div className="stepsInfoEvaluation">
                <div className="stepCompleteInfoEvaluation" />
                <div className="stepCompleteInfoEvaluation" />
              </div>
            </div>

            <div className="contentInputContainerInfoEvaluation">
              <div className="containerCompleteInfoEvaluation">
                <div className="inputContainerCompleteInfoEvaluation">
                  <h1>Responsável</h1>
                  <span>{area?.manager_user.name}</span>
                </div>
              </div>
              <div className="containerCompleteInfoEvaluation">
                <div className="inputContainerCompleteInfoEvaluation">
                  <h1>Pasto</h1>
                  <span>{microArea?.farm_initials}-{microArea?.area_initials}-{microArea?.micro_area_initials}</span>
                </div>
              </div>
            </div>
            <div className="mapContainerInfoEvaluation">
              <StaticMapRender
                farm={area?.map_coords}
                mainPolygon={microArea?.micro_area_map_coords || []}
                microAreas={[]}
                fitCoords={microArea?.micro_area_map_coords}
              />
            </div>
          </div>
        </main>
      </section>
    </Modal >
  )
}